<template>
  <v-card elevation="3" class="rounded-10 campaign-creator" min-height="100vh">
    <v-card-title
      class="primary--text d-flex align-center justify-space-between fw-700"
    >
      <span class="ml-2">{{ title }}</span>
      <v-btn @click="goBack" icon>
        <v-icon large color="primary">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form v-model="valid">
        <v-row dense align="stretch" justify="start">
          <v-col md="9">
            <v-stepper
              v-model="progress"
              light
              class="border-color--grey border-1"
            >
              <v-stepper-header>
                <v-stepper-step
                  :rules="[step1Rules]"
                  :complete="progress > 1"
                  :step="1"
                  class="fw-700"
                >
                  Settings
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  :rules="[step2Rules]"
                  :complete="progress > 2"
                  :step="2"
                  class="fw-700"
                >
                  Content & Schedule
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :rules="[step3Rules]" :step="3" class="fw-700">
                  Review & {{ isEdit ? 'Update' : 'Save' }}
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content :step="1" class="pa-1">
                  <v-card flat :min-height="400">
                    <v-card-text>
                      <label class="subtitle-2 primary--text">
                        Campaign Name (Required)
                      </label>
                      <v-text-field
                        outlined
                        dense
                        clearable
                        clear-icon="mdi-close-circle-outline"
                        v-model="mapping.name"
                        hide-details="auto"
                        placeholder="Required"
                        :rules="requiredRules"
                        class="mb-2"
                      ></v-text-field>
                      <label class="subtitle-2 primary--text">
                        Groups Recipients
                        <span class="fw-500 fs-11">
                          (Optional if Individual Recipients is provided)
                        </span>
                      </label>
                      <v-select
                        dense
                        outlined
                        :rules="requiredRules"
                        clear-icon="mdi-close-circle-outline"
                        placeholder="Groups Recipients"
                        hide-details="auto"
                        multiple
                        small-chips
                        :items="lead_groups"
                        item-text="name"
                        item-value="id"
                        deletable-chips
                        chips
                        clearable
                        v-model="mapping.groups"
                        class="mb-2"
                      ></v-select>
                      <label class="subtitle-2 primary--text">
                        Individual Recipients
                        <span class="fw-500 fs-11">
                          (Optional if Groups Recipients is provided)
                        </span>
                      </label>
                      <v-autocomplete
                        v-model="mapping.leads"
                        :items="search_items"
                        :loading="isLoading"
                        :search-input.sync="search_word"
                        chips
                        deletable-chips
                        multiple
                        clearable
                        clear-icon="mdi-close-circle-outline"
                        hide-details="auto"
                        item-text="fullname"
                        item-value="id"
                        placeholder="Search for leads"
                        :rules="requiredRules"
                        outlined
                        dense
                        class="mb-3"
                        cache-items
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              Search for
                              <strong>leads</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                        <template
                          v-slot:selection="{ attr, on, item, index, selected }"
                        >
                          <v-chip
                            v-bind="attr"
                            :input-value="selected"
                            label
                            small
                            outlined
                            clearable
                            v-on="on"
                            v-if="index < 5"
                          >
                            <span v-text="item.fullname"></span>
                          </v-chip>
                          <v-chip
                            v-bind="attr"
                            :input-value="selected"
                            label
                            small
                            outlined
                            clearable
                            v-on="on"
                            v-if="index === 5"
                          >
                            <span>
                              + {{ mapping.leads.length - 5 }} others
                            </span>
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-content :key="item.id">
                            <v-list-item-title
                              v-text="item.fullname"
                            ></v-list-item-title>
                            <v-list-item-subtitle>
                              <span v-if="item.contacts && item.contacts.input">
                                {{ item.contacts.input }}
                              </span>
                              <span class="error--text" v-else>
                                No contact number
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                      <label class="subtitle-2 primary--text">
                        Use Twilio Number
                      </label>
                      <v-select
                        outlined
                        dense
                        item-text="friendly_name"
                        item-value="id"
                        :items="twilio_numbers"
                        clearable
                        clear-icon="mdi-close-circle-outline"
                        v-model="mapping.twilio_number"
                        :rules="[requiredRule()]"
                        hide-details="auto"
                        placeholder="Required"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content :key="item.id">
                            <v-list-item-title
                              class="priamry--text"
                              v-text="item.friendly_name"
                            ></v-list-item-title>
                            <v-list-item-subtitle>
                              {{ item.source_number }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-select>
                      <label class="subtitle-2 primary--text">
                        Email Response Notification
                      </label>
                      <v-text-field
                        outlined
                        dense
                        clearable
                        clear-icon="mdi-close-circle-outline"
                        v-model="mapping.email_response_to"
                        :rules="emailRules"
                        hide-details="auto"
                        placeholder="Optional"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </v-stepper-content>
                <v-stepper-content :step="2" class="pa-1">
                  <v-card flat :min-height="400">
                    <v-card-text>
                      <v-row
                        dense
                        align="start"
                        justify="center"
                        class="mb-2 flex-wrap"
                      >
                        <v-col
                          cols="12"
                          sm="12"
                          md="4"
                          class="d-flex flex-column align-start justify-start"
                        >
                          <label class="subtitle-2 primary--text">
                            Operating Hours (From)
                          </label>
                          <CustomTimePicker
                            outlined
                            dense
                            class="fullwidth"
                            :max="mapping.op_hours_to"
                            placeholder="Optional"
                            v-model="mapping.op_hours_from"
                          ></CustomTimePicker>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="4"
                          class="d-flex flex-column align-start justify-start"
                        >
                          <label class="subtitle-2 primary--text">
                            Operating Hours (Until)
                          </label>
                          <CustomTimePicker
                            outlined
                            dense
                            class="fullwidth"
                            :min="mapping.op_hours_from"
                            :disabled="!mapping.op_hours_from"
                            placeholder="Required if Operating Hours(From) is set"
                            v-model="mapping.op_hours_to"
                          ></CustomTimePicker>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="4"
                          class="d-flex flex-column align-start justify-start"
                        >
                          <label class="subtitle-2 primary--text">
                            Operating Timezone
                          </label>
                          <Timezone
                            dense
                            class="fullwidth"
                            required
                            v-model="mapping.timezone"
                          ></Timezone>
                        </v-col>
                      </v-row>
                      <label class="my-2 subtitle-2 primary--text">
                        Iterations (Required)
                      </label>
                      <SingleSmsMmsIteration
                        v-for="(iteration, index) in mapping.iteration_items"
                        :key="index"
                        class="mb-1"
                        :value="mapping.iteration_items[index]"
                        @delete="handleDelete(index)"
                        @input="handleUpdate($event, index)"
                      ></SingleSmsMmsIteration>
                      <v-btn
                        block
                        :disabled="is_max_iterations"
                        outlined
                        @click="addNewIteration"
                        class="text-none fw-900 border-dashed mx-1 my-3"
                        color="primary"
                      >
                        <v-icon left>mdi-plus</v-icon> New Iteration
                        <span v-if="is_max_iterations">
                          (Max of {{ max_iteration }})
                        </span>
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-stepper-content>
                <v-stepper-content :step="3" class="pa-1">
                  <v-card flat :min-height="400">
                    <v-card-text>
                      <v-sheet class="mb-1 rounded-10 fullwidth">
                        <v-row
                          dense
                          class="border-1 pa-3 rounded-tl-10 rounded-tr-10"
                          align="center"
                          justify="start"
                        >
                          <v-col
                            class="primary--text fw-700"
                            cols="12"
                            md="3"
                            sm="12"
                          >
                            Campaign Name :
                          </v-col>
                          <v-col
                            class="primary--text fw-900"
                            cols="12"
                            md="9"
                            sm="12"
                          >
                            {{
                              mapping.name ||
                              'Not set. This is a required field!'
                            }}
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          class="border-1 pa-3"
                          align="center"
                          justify="start"
                        >
                          <v-col
                            class="primary--text fw-700"
                            cols="12"
                            md="3"
                            sm="12"
                          >
                            Group Recipients :
                          </v-col>
                          <v-col
                            class="primary--text fw-900 d-flex align-center flex-wrap justify-start"
                            cols="12"
                            md="9"
                            sm="12"
                          >
                            <v-chip
                              small
                              class="mr-1"
                              outlined
                              color="primary"
                              v-for="group in groups_selected"
                              :key="group.id"
                            >
                              {{ group.name | ucwords }}
                            </v-chip>
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          class="border-1 pa-3"
                          align="center"
                          justify="start"
                        >
                          <v-col
                            class="primary--text fw-700"
                            cols="12"
                            md="3"
                            sm="12"
                          >
                            Individual Recipients :
                          </v-col>
                          <v-col
                            class="primary--text fw-900 d-flex align-center flex-wrap justify-start"
                            cols="12"
                            md="9"
                            sm="12"
                          >
                            <v-chip
                              small
                              class="mr-1"
                              outlined
                              color="primary"
                              v-for="lead in leads_selected"
                              :key="lead.id"
                            >
                              {{ lead.fullname | ucwords }}
                            </v-chip>
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          class="border-1 pa-3"
                          align="center"
                          justify="start"
                        >
                          <v-col
                            class="primary--text fw-700"
                            cols="12"
                            md="3"
                            sm="12"
                          >
                            Use email for response notification :
                          </v-col>
                          <v-col
                            class="primary--text fw-900"
                            cols="12"
                            md="9"
                            sm="12"
                          >
                            {{ mapping.email_response_to || 'None' }}
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          class="border-1 pa-3"
                          align="center"
                          justify="start"
                        >
                          <v-col
                            class="primary--text fw-700"
                            cols="12"
                            md="3"
                            sm="12"
                          >
                            Operating Hours:
                          </v-col>
                          <v-col
                            class="primary--text fw-900"
                            cols="12"
                            md="9"
                            sm="12"
                          >
                            <span v-if="mapping.op_hours_from">
                              {{ mapping.op_hours_from || 'None' }} to
                              {{ mapping.op_hours_to || 'None' }}
                            </span>
                            <span v-else class="primary--text">
                              Using system default
                            </span>
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          class="border-1 pa-3"
                          align="center"
                          justify="start"
                        >
                          <v-col
                            class="primary--text fw-700"
                            cols="12"
                            md="3"
                            sm="12"
                          >
                            Operating Timezone:
                          </v-col>
                          <v-col
                            class="primary--text fw-900"
                            cols="12"
                            md="9"
                            sm="12"
                          >
                            {{ mapping.timezone || 'UTC (+00:00)' }}
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          class="border-1 pa-3 rounded-bl-10 rounded-br-10"
                          align="center"
                          justify="start"
                        >
                          <v-col
                            class="primary--text fw-700"
                            cols="12"
                            md="3"
                            sm="12"
                          >
                            Number of Iteration :
                          </v-col>
                          <v-col
                            class="primary--text fw-900"
                            cols="12"
                            md="9"
                            sm="12"
                          >
                            {{
                              mapping.iteration_items.length ||
                              'No iteration found. This is a required field!'
                            }}
                          </v-col>
                        </v-row>
                      </v-sheet>
                      <v-sheet class="mb-1 rounded-10 fullwidth">
                        <v-window class="my-2" v-model="slider" show-arrows>
                          <v-window-item
                            v-for="iteration of mapping.iteration_items"
                            :key="iteration.step"
                          >
                            <v-card
                              flat
                              outlined
                              class="fullwidth d-flex flex-column"
                              min-height="200"
                            >
                              <v-card-title
                                class="primary fs-15 py-1 light--text"
                              >
                                <strong>Iteration : </strong>
                                <strong class="ml-1">
                                  {{ iteration.step }}
                                </strong>
                              </v-card-title>
                              <v-card-text class="py-4">
                                <v-row dense align="center">
                                  <v-col
                                    cols="12"
                                    md="6"
                                    sm="12"
                                    class="d-flex align-center justify-center flex-column"
                                  >
                                    <strong
                                      class="secondary--text fs-15 fw-900"
                                    >
                                      Content Type
                                    </strong>
                                    <span class="primary--text">
                                      {{ iteration.content_type.toUpperCase() }}
                                    </span>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="6"
                                    sm="12"
                                    class="d-flex align-center justify-center flex-column"
                                  >
                                    <strong
                                      class="secondary--text fs-15 fw-900"
                                    >
                                      Attachment
                                    </strong>
                                    <v-avatar
                                      v-if="
                                        iteration.content_type === 'mms' &&
                                        iteration.attachment
                                      "
                                      size="100"
                                    >
                                      <v-img
                                        :src="iteration.attachment.url_inline"
                                        contain
                                      ></v-img>
                                    </v-avatar>
                                    <span
                                      class="error--text"
                                      v-if="
                                        iteration.content_type === 'mms' &&
                                        !iteration.attachment
                                      "
                                    >
                                      No attachment provided
                                    </span>
                                    <span class="primary--text" v-else>
                                      Using SMS
                                    </span>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="6"
                                    sm="12"
                                    class="d-flex align-center justify-center flex-column"
                                  >
                                    <strong
                                      class="secondary--text fs-15 fw-900"
                                    >
                                      Content
                                    </strong>
                                    <div class="primary--text pa-2">
                                      <span v-if="iteration.content">
                                        {{
                                          iteration.content
                                            | trunc_mid(200, 200)
                                        }}
                                      </span>
                                      <span v-else class="error--text">
                                        No SMS content provided
                                      </span>
                                    </div>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="6"
                                    sm="12"
                                    class="d-flex align-center justify-center flex-column"
                                  >
                                    <strong
                                      class="secondary--text fs-15 fw-900"
                                    >
                                      Schedule
                                    </strong>
                                    <v-chip
                                      outlined
                                      color="primary"
                                      class="mx-1"
                                    >
                                      {{
                                        iteration.schedule.startDate
                                          | format('LL')
                                      }}
                                      to
                                      {{
                                        iteration.schedule.endDate
                                          | format('LL')
                                      }}
                                    </v-chip>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-window-item>
                        </v-window>
                      </v-sheet>
                    </v-card-text>
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
            <v-row dense class="my-2 pa-2" align="center" justify="center">
              <v-btn
                min-width="150"
                v-if="progress > 1"
                color="primary"
                class="text-none mx-2"
                @click="progress--"
              >
                Back
              </v-btn>
              <v-btn
                min-width="150"
                v-if="progress < 3"
                color="primary"
                class="text-none mx-2"
                :disabled="!can_next"
                @click="goNext"
              >
                Next
              </v-btn>
              <v-btn
                min-width="120"
                v-if="progress > 2"
                :loading="btnloading"
                color="primary"
                class="text-none"
                :disabled="!can_submit"
                @click="save"
              >
                <v-icon left color="white">save</v-icon>
                {{ isEdit ? 'Update' : 'Save' }}
              </v-btn>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="mdAndUp" md="3">
            <v-sheet
              color="#8092b8"
              outlined
              class="d-flex ocerflow-hidden align-end flex-column justify-end rounded-5 pb-2"
              height="100%"
            >
              <v-img
                :lazy-src="settings.loader"
                contain
                max-height="350"
                :src="side_image"
              ></v-img>
            </v-sheet>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <DeleteDialog
      v-model="confirm_edit_campaign"
      title="Updating Campaign"
      text-content="Are you sure you want to update this campaign?<br> This will remove any previous iterations and pending emails generated for this campaign."
      @delete="proceedUpdate"
      delete-button-text="Yes! Update Campaign"
    ></DeleteDialog>
  </v-card>
</template>

<script>
import request from '@/services/axios_instance'
import DeleteDialog from '@/common/DeleteDialog.vue'
import _debounce from 'lodash/debounce'
import _cloneDeep from 'lodash/cloneDeep'
import { fetch_services } from '@/services/fetch/fetch_services'
import SingleSmsMmsIteration from '../components/SingleSmsMmsIteration.vue'
import CustomTimePicker from '@/common/Pickers/CustomTimePicker.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Timezone from '@/common/Forms/Timezone.vue'
export default {
  components: {
    DeleteDialog,
    SingleSmsMmsIteration,
    CustomTimePicker,
    Timezone
  },
  mixins: [fetch_services],
  data: () => ({
    slider: null,
    side_image: require('@/assets/temp/pngwing-sms.png'),
    confirm_edit_campaign: false,
    progress: 1,
    max_iteration: 10,
    preview_step: 0,
    valid: false,
    btnloading: false,
    search_items: [],
    isLoading: false,
    search_word: null,
    placeholders: [
      'target_first_name',
      'target_last_name',
      'target_fullname',
      'target_organization',
      'target_email',
      'from_website',
      'from_organization'
    ],
    mapping: {
      name: null,
      twilio_number: null,
      timezone: 'UTC',
      email_response_to: null,
      op_hours_from: '06:00',
      op_hours_to: '23:59',
      leads: [],
      groups: [],
      iteration_items: []
    }
  }),
  computed: {
    ...mapGetters('campaigns', [
      'lead_groups',
      'campaign',
      'selected_leads',
      'twilio_numbers'
    ]),
    is_max_iterations() {
      return this.mapping.iteration_items.length >= this.max_iteration
    },
    isEdit() {
      return !!this.campaign
    },
    title() {
      return this.isEdit
        ? `Edit SMS/MMS Campaign (${this.campaign.name})`
        : 'Create New SMS/MMS Campaign'
    },
    can_proceed_2() {
      return this.step1Rules()
    },
    can_proceed_3() {
      return this.step2Rules()
    },
    can_submit() {
      return this.step3Rules()
    },
    can_next() {
      if (this.progress === 1) return this.can_proceed_2
      else if (this.progress === 2) return this.can_proceed_3
      else return false
    },
    payload() {
      return {
        name: this.mapping.name,
        twilio_number: this.mapping.twilio_number,
        email_response_to: this.mapping.email_response_to,
        groups: this.mapping.groups,
        leads: this.mapping.leads,
        iterations: this.mapping.iteration_items,
        timezone: this.mapping.timezone,
        op_hours_from: this.mapping.op_hours_from || null,
        op_hours_to: this.mapping.op_hours_to || null
      }
    },
    groups_selected() {
      return this.lead_groups.filter((i) => this.mapping.groups.includes(i.id))
    },
    leads_selected() {
      return this.selected_leads.filter((i) =>
        this.mapping.leads.includes(i.id)
      )
    }
  },
  created() {
    this.fetchTwilioNumbers()
    this.getGroups()
  },
  watch: {
    '$route.params.id': {
      handler: function (val) {
        if (val)
          this.fetchCampaign({
            id: val,
            cb: (data) => this.mapItem()
          })
        else this.set_campaign(null)
      },
      immediate: true
    },
    'mapping.iteration_items': {
      handler: function (val) {
        if (!this.isEdit && (!val || !val.length)) this.addNewIteration()
      },
      immediate: true
    },
    search_word: _debounce(function (val) {
      this.search(val)
    }, 500)
  },
  methods: {
    ...mapActions('campaigns', [
      'fetchCampaign',
      'fetchTwilioNumbers',
      'getSelectedLeads',
      'getGroups'
    ]),
    ...mapMutations('campaigns', ['set_campaign', 'set_selected_leads']),
    goNext() {
      this.progress += 1
      if (this.progress === 3 && this.mapping.leads.length)
        this.getSelectedLeads(this.mapping.leads)
    },
    mapItem() {
      let item = _cloneDeep(this.campaign)
      this.mapping.name = item.name
      this.mapping.twilio_number = item.twilio_number_id
      this.mapping.timezone = item.props.timezone || 'UTC'
      this.mapping.op_hours_from = item.props.op_hours_from || '06:00'
      this.mapping.op_hours_to = item.props.op_hours_to || '23:59'
      this.mapping.email_response_to = item.props.email_response_to || null
      this.mapping.groups = item.groups.map((i) => {
        return i.hasOwnProperty('id') ? i.id : i
      })
      this.mapping.leads = item.leads.map((i) => {
        return i.hasOwnProperty('id') ? i.id : i
      })
      this.search_items = item.leads
      this.set_selected_leads(item.leads)
      this.mapping.iteration_items = item.iterations.map((i, index) => {
        return {
          step: index + 1,
          attachment: i.props.attachment,
          content_type: i.props.content_type,
          content: i.props.message,
          schedule: {
            startDate: this.moment(i.startDate).format('YYYY-MM-DD'),
            endDate: this.moment(i.endDate).format('YYYY-MM-DD')
          }
        }
      })
    },
    save() {
      if (!this.isEdit) {
        this.btnloading = true
        this.handleSaveSMSCampaign(this.payload)
      } else {
        this.confirm_edit_campaign = true
      }
    },
    proceedUpdate() {
      this.btnloading = true
      this.confirm_edit_campaign = false
      this.updateItem(
        `api/lead-campaign/sms/${this.campaign.id}`,
        this.payload,
        'Campaign updated',
        () => {
          this.btnloading = false
          this.$router.push({ name: 'campaigns', params: { type: 'sms' } })
        },
        null
      )
    },
    search(keyword) {
      if (
        !this.isLoading &&
        keyword &&
        (keyword !== 'undefined' || typeof keyword === 'undefined')
      ) {
        this.isLoading = true
        request
          .get(`api/leads?search=${keyword}&per_page=30`)
          .then(({ data }) => {
            if (data.data) this.search_items = data.data
          })
          .finally(() => (this.isLoading = false))
      }
    },
    step1Rules() {
      let has_recipient =
        this.mapping.groups.length || this.mapping.leads.length ? true : false
      return !!(this.mapping.name && this.mapping.name.length && has_recipient)
    },
    step2Rules() {
      if (!this.mapping.iteration_items.length) return false
      for (let i = 0; i < this.mapping.iteration_items.length; i++) {
        let iteration = this.mapping.iteration_items[i]
        let hasContent =
          iteration.content_type === 'mms'
            ? iteration.attachment
              ? true
              : false
            : iteration.content
            ? true
            : false
        if (
          !hasContent ||
          !iteration.schedule.startDate ||
          !iteration.schedule.endDate
        )
          return false
      }
      return this.mapping.timezone ? true : false
    },
    step3Rules() {
      return this.step1Rules() && this.step2Rules()
    },
    addNewIteration() {
      this.mapping.iteration_items.push({
        step: this.mapping.iteration_items.length + 1,
        content_type: 'sms',
        content: null,
        attachment: null,
        schedule: {
          startDate: null,
          endDate: null
        }
      })
    },
    handleUpdate(payload, index) {
      this.mapping.iteration_items.splice(index, 1, payload)
    },
    handleDelete(index) {
      this.mapping.iteration_items.splice(index, 1)
    },
    goBack() {
      if (this.$route.query.redirect_uri) {
        this.$router.push({ path: this.$route.query.redirect_uri })
      } else {
        this.$router.go(-1)
      }
    },
    handleSaveSMSCampaign(payload) {
      this.addItem(
        `api/lead-campaign/sms`,
        payload,
        'New SMS/MMS campaign added!',
        () => {
          this.$router.push({ name: 'campaigns', params: { type: 'sms' } })
        },
        null
      )
    }
  }
}
</script>

<style lang="scss">
.campaign-creator {
  .v-stepper {
    box-shadow: none !important;

    .v-stepper__header {
      box-shadow: none !important;

      .v-stepper__step__step {
        padding: 15px;
        border-radius: 5px;
        font-size: 17px;
      }
    }
  }
}
</style>
