<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-if="icon"
        :outlined="outlined"
        v-model="time_formatted"
        :label="label"
        :disabled="disabled"
        hide-details="auto"
        :prepend-icon="icon"
        readonly
        :placeholder="placeholder"
        :dense="dense"
        :filled="filled"
        v-bind="attrs"
        v-on="on"
        :value="time_formatted"
        class="fullwidth"
        clearable
        clear-icon="mdi-close-circle-outline"
      ></v-text-field>
      <v-text-field
        v-else
        :outlined="outlined"
        v-model="time_formatted"
        :label="label"
        readonly
        :disabled="disabled"
        :dense="dense"
        :filled="filled"
        v-bind="attrs"
        v-on="on"
        :value="time_formatted"
        :placeholder="placeholder"
        clearable
        class="fullwidth"
        clear-icon="mdi-close-circle-outline"
        hide-details="auto"
      ></v-text-field>
    </template>

    <v-time-picker
      ampm-in-title
      v-model="time"
      full-width
      :min="min"
      :max="max"
      @click:minute="$refs.menu.save(time)"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'CustomTimePicker',
  props: {
    label: { type: String, default: null },
    value: { type: String, default: null },
    icon: { type: String, default: null },
    min: { type: String, default: null },
    max: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
    placeholder: { type: String, default: null }
  },
  data: () => ({
    time: null,
    menu: false,
    time_formatted: null
  }),
  watch: {
    value(val) {
      this.time = val
      if (val) {
        this.time_formatted = this.moment(`2021-01-01 ${val}:00`).format(
          'hh:mm A'
        )
      }
    },
    time: {
      handler: function (val) {
        if (val) {
          this.time_formatted = this.moment(`2021-01-01 ${val}:00`).format(
            'hh:mm A'
          )
        }
        this.$emit('input', val)
      },
      immediate: true
    }
  }
}
</script>

<style scoped></style>
