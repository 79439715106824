<template>
  <v-card
    flat
    class="iteration rounded-10 border-2"
    :class="[isValid ? 'border-color--primary' : 'border-color--error']"
  >
    <v-card-title
      @click="show = !show"
      :class="[isValid ? 'primary--text' : 'error--text']"
      class="d-flex align-center py-2 cursor-pointer justify-space-between"
    >
      <span class="fs-16 fw-700">Iteration {{ iteration.step }}</span>
      <span class="fw-500 fs-13">{{ normalize_range }}</span>
      <div>
        <HelpToolTip
          icon-color="primary"
          @click:icon="$emit('delete')"
          icon="mdi-delete"
          text="Delete Iteration"
        ></HelpToolTip>
        <v-icon color="primary">
          {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </div>
    </v-card-title>
    <v-expand-transition>
      <v-card-text v-show="show">
        <v-row align="center" justify="start">
          <v-col cols="12" md="12" sm="12">
            <label class="subtitle-2 primary--text"> Content Type : </label>
            <v-radio-group
              class="mx-auto mt-1 text-center"
              v-model="iteration.content_type"
              row
              dense
              color="primary"
              hide-details="auto"
            >
              <v-radio value="sms">
                <template v-slot:label>
                  <span class="fw-700 primary--text">
                    Short Message Service (SMS)
                  </span>
                </template>
              </v-radio>
              <v-radio value="mms">
                <template v-slot:label>
                  <span class="fw-700 primary--text">
                    Multimedia Messaging Service (MMS)
                  </span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row align="center" justify="start">
          <v-col
            cols="12"
            class="d-flex flex-column justify-start"
            md="12"
            v-if="iteration.content_type === 'mms'"
          >
            <label class="subtitle-2 primary--text">
              MMS Attachment (Max filesize: 5MB)
            </label>
            <v-hover v-slot:default="{ hover }">
              <v-card
                class="align-center rounded-10 my-2 d-flex justify-center"
                min-width="200"
                width="200"
                flat
                outlined
                max-width="250"
              >
                <v-card-text class="d-flex align-center justify-center">
                  <v-avatar tile v-if="!iteration.attachment" size="100%">
                    <v-img
                      contain
                      :src="require('@/assets/temp/no-image.jpg')"
                    ></v-img>
                  </v-avatar>
                  <Attachment
                    v-else
                    :item="iteration.attachment"
                    animated
                  ></Attachment>
                </v-card-text>
                <transition>
                  <v-card
                    @click.stop="openUploader"
                    color="transparent"
                    class="
                      transition-fast-in-fast-out
                      d-flex
                      align-center
                      justify-center
                      v-card--reveal
                    "
                    style="height: 100%"
                    v-if="hover"
                  >
                    <v-icon color="primary" size="80">mdi-camera</v-icon>
                  </v-card>
                </transition>
              </v-card>
            </v-hover>
            <v-file-input
              ref="fileuploader"
              class="d-none"
              accept="image/*,video/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              @change="setAttachment"
              v-model="uploader"
            >
            </v-file-input>
          </v-col>
          <v-col cols="12" md="12">
            <label class="subtitle-2 primary--text">
              Enter contents for this iteration
            </label>
            <v-textarea
              clearable
              placeholder="Required, Enter text"
              auto-grow
              hide-details="auto"
              :rows="3"
              :rules="[requiredRule()]"
              outlined
              class="mb-2"
              v-model="iteration.content"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row dense align="center" justify="start">
          <v-col cols="12" md="12" sm="12">
            <label class="subtitle-2 primary--text"> Schedule </label>
            <span class="caption ml-2">
              (This iteration will be sent one time during this period)
            </span>
            <v-sheet
              class="fullwidth d-flex align-center justify-center"
              style="overflow-y: auto"
            >
              <DateRangePicker
                class="mt-2 mx-auto"
                :min-date="today"
                opens="inline"
                :locale-data="locale_data"
                :date-range="iteration.schedule"
                :show-week-numbers="false"
                :ranges="false"
                :time-picker="false"
                :show-dropdowns="false"
                auto-apply
                @update="handleUpdate($event)"
              >
                <template v-slot:input="picker" style="min-width: 300px">
                  <span class="primary--text fw-700" v-if="picker.startDate">
                    {{ normalize_range_by(picker.startDate, picker.endDate) }}
                  </span>
                  <span class="error--text fw-700" v-else>
                    Select start date and end date from below
                  </span>
                </template>
              </DateRangePicker>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import _ from 'lodash'
import request from '@/services/axios_instance'
export default {
  name: 'SingleTemplateIteration',
  components: {
    DateRangePicker
  },
  props: {
    value: { type: Object }
  },
  computed: {
    today() {
      return this.moment(new Date()).format('YYYY-MM-DD')
    },
    isValid() {
      if (!this.iteration) return false
      let hasAttachment =
        this.iteration.content_type === 'mms'
          ? this.iteration.attachment
            ? true
            : false
          : true
      return this.iteration.schedule.startDate &&
        this.iteration.schedule.endDate &&
        hasAttachment
        ? true
        : false
    },
    normalize_range() {
      if (
        !this.iteration.schedule.startDate ||
        !this.iteration.schedule.endDate
      )
        return `No dates selected`
      return `Around ${this.moment(this.iteration.schedule.startDate).format(
        'll'
      )} to ${this.moment(this.iteration.schedule.endDate).format('ll')}`
    }
  },
  data: () => ({
    max_attachment_size: 5000000, //5mb to bytes
    uploader: null,
    show: true,
    isEditting: false,
    iteration: {
      step: 1,
      content_type: 'sms',
      content: null,
      attachment: null,
      schedule: {
        startDate: null,
        endDate: null
      }
    },
    locale_data: {
      firstDay: 1,
      format: 'yyyy-mm-dd'
    }
  }),
  watch: {
    value: {
      handler: function (val) {
        if (val) {
          this.iteration = val
        }
      },
      immediate: true,
      deep: true
    },
    iteration: {
      handler: function (val) {
        this.$emit('input', val)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleUpdate(event) {
      this.$set(this.iteration, 'schedule', {
        startDate: this.moment(event.startDate).format('YYYY-MM-DD'),
        endDate: this.moment(event.endDate).format('YYYY-MM-DD')
      })
    },
    createNewTemplate() {
      this.$router
        .push({
          name: 'builders-email--template-campaign',
          query: { redirect_uri: this.$route.fullPath }
        })
        .catch(() => {})
    },
    normalize_range_by(from, to) {
      if (!from || !to) return `No dates selected`
      return `Around ${this.moment(from).format('LL')} to ${this.moment(
        to
      ).format('LL')}`
    },
    openUploader() {
      this.$refs.fileuploader.$refs.input.click()
    },
    setAttachment(file) {
      if (file.size <= this.max_attachment_size) {
        let formData = new FormData()
        formData.append('file', file)
        request.post(`api2/attachments/dropzone`, formData).then(
          ({ data }) => {
            this.$set(this.iteration, 'attachment', data)
          },
          (error) => {
            this.appSnackbar(error, 'error')
          }
        )
      } else {
        this.appSnackbar(
          `${file.name} exceed allowed max filesize limit`,
          'error'
        )
      }
    }
  }
}
</script>

<style lang="css">
.iteration .daterangepicker td.in-range {
  background-color: #223a6b;
  color: #fbf7f7;
}

.iteration .v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
